#cookie_popover_container {
  background: $color-white none repeat scroll 0 0;
  position: fixed;
  bottom: 0;
  right: 0;
  color: $color-black;
  display: none;
  padding: 22px 30px 22px 22px;
  width: 300px;
  border: 10px solid $color-black;
  z-index: 1000000;
  @include breakpoint(641px) {
    width: 295px;
    height: 150px;
    right: 10px;
    bottom: 10px;
    border: 5px solid $color-black;
  }
  a {
    text-transform: none;
  }
  .cookie_legal-close_box {
    background-image: url(/sites/clinique/themes/cl_base/img/icon-close.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    right: 5px;
    top: 6px;
    cursor: pointer;
    height: 15px;
    width: 15px;
    display: inline-block;
  }
}
/* Mobile */

.device-mobile {
  #cookie_popover_container {
    border: 10px solid $color-black;
    bottom: 0;
    height: 150px;
    padding: 15px;
    right: 0;
    width: 100%;
  }
}
