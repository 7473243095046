.spp_page_wrap {
  .spp-share-container {
    .BVRRPrimarySummary {
      .BVRRSocialBookmarkingLinks {
        .BVRRSocialBookmarkingSharingLinkTwitter,
        .BVRRSocialBookmarkingSharingLinkPinterest {
          display: none;
        }
      }
    }
  }
}

.spp_reviews {
  .BVRRSocialBookmarkingLinks,
  .BVRRReviewSocialLinksContainer {
    a.BVRRSocialBookmarkingSharingLinkTwitter,
    a.BVRRSocialBookmarkingSharingLinkPinterest {
      display: none;
    }
  }
}

#sticky-bar {
  #sticky_bar_content {
    .sticky_content {
      .sticky_prod_select {
        float: none;
      }
    }
  }
}

.tertiary-nav {
  li {
    &.nav-language-switcher-sticky {
      width: 60px;
      padding: 7px 0px 0px 0px;
      .links-container {
        .link1 {
          border-right: 1px solid $color-black;
        }
        a {
          padding: 0 5px;
          color: $color-darker-grey;
          &:hover {
            color: $color-darker-grey;
          }
        }
      }
      img {
        padding-top: 10px;
      }
    }
  }
}

#ff_spp {
  &.foundation-finder {
    display: none;
  }
}

.account-favorites-wishlist .favorite-products-grid .favorite {
  .favorite-product {
    .product-info {
      .price {
        margin: 1px 4px 0 0;
      }
    }
  }
}

#cookie_popover_container {
  display: none !important;
}

.optanon-toggle-display,
.optanon-allow-all {
  &:focus {
    outline: none;
  }
}

.device-mobile {
  .single-video-v1 {
    border-bottom: 0px solid $color-white;
    &.single-video-wrapper,
    & .video-wrapper {
      background-size: contain;
      background-repeat: no-repeat;
    }
    .image-placeholder {
      .image-placeholder-img {
        display: block;
        width: 100%;
        max-width: auto;
      }
    }
    .image-title {
      display: none;
    }
  }
  .single-video {
    iframe {
      width: 100%;
    }
  }
  #foreground-node {
    &.product-overlay {
      top: 0 !important;
    }
  }
}

.single-video-v1 {
  .video-wrapper {
    .video_player_container {
      .video-player-wrapper {
        .video_player {
          video {
            max-height: 806px;
          }
        }
      }
    }
  }
  .outer-wrap {
    max-width: 1920px;
  }
  .image-title {
    position: absolute;
    top: 80px;
    #{$ldirection}: unset;
    text-align: center;
    width: 100%;
  }
}

.outer-wrap {
  padding-#{$ldirection}: 0;
  padding-#{$rdirection}: 0;
}

.header-gnav-navigation {
  .gnav-header-formatter {
    &__sections {
      &--pc {
        [lang='fr'] & {
          margin-#{$ldirection}: 0;
        }
        .header-gnav-section {
          &__label,
          &__link {
            @media #{$cr19-large-up-landscape} {
              margin: 0 6px;
              [lang='fr'] & {
                margin: 0 3px;
              }
            }
            @media #{$cr19-xlarge-up} {
              margin: 0 15px;
              [lang='fr'] & {
                padding: 0 15px;
              }
            }
          }
        }
      }
    }
  }
}

.page-footer {
  .email-signup-block {
    &__form-content {
      &-fields {
        display: flex;
        flex-flow: row wrap;
        .email-signup-block {
          &__form-pc-email-promotions {
            order: -1;
            margin-#{$ldirection}: 2px;
            @media #{$large-up} {
              bottom: 92%;
            }
            @media #{$cr19-medium-portrait-only} {
              bottom: 70%;
            }
            @media #{$cr19-medium-landscape-only} {
              bottom: 68%;
            }
          }
          &__form-email,
          &__form-cta {
            margin-top: 5%;
            @media #{$medium-up} {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
.registration_content {
  .email_promotions {
    .pc_email_promo_container {
      .label-content {
        font-family: $helvetica-regular-font-family;
      }
    }
  }
}
