// Collection formatter - main container
.appt-book {
  .service-select {
    .service {
      min-height: 340px;
      @include breakpoint($ab-small-down) {
        min-height: 210px;
      }
    }
  }
}
