.module-spp-detail {
  .icon-message {
    float: none;
  }
  .icon-message.active {
    margin-right: 55px;
    margin-top: 5px;
  }
}
.sd-product-spp {
  .elc-product-full-sticky-menu {
    &.elc-product-full-sticky-menu-shaded {
      .elc-product-full-sticky-menu-row {
        .elc-grid-column {
          &.elc-sticky-menu-product-left-cta,
          &.elc-sticky-menu-product-cta {
            @media #{$cr19-medium-up} {
              width: 25%;
            }
          }
        }
      }
    }
  }
}
