#sticky-bar {
  .outer-wrap {
    max-width: 1040px;
    position: relative;
  }
  position: fixed;
  top: -48px;
  width: 100%;
  z-index: 9998;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 0.3);
  .site-logo {
    width: 98px;
    float: #{$ldirection};
    padding-top: 14px;
  }
  .gnav-drop-down-v1 {
    height: 28px;
  }
  #sticky_bar_content {
    color: #1f1f1f;
    padding: 0;
    h3 {
      margin: 0;
      font-size: 22px;
      color: $color-cl-green-2;
      padding: 10px 20px;
      float: #{$ldirection};
      .item-count {
        display: none;
      }
    }
    .sticky_content {
      /* width: 771px; */
      .sticky_prod_info {
        float: #{$ldirection};
        @include swap_direction(padding, 0 10px 0 10px);
        max-width: 30%;
        position: relative;
        img {
          position: absolute;
        }
        h4 {
          float: #{$ldirection};
          @include swap_direction(padding, 5px 0 0 45px);
          height: 45px;
          overflow: hidden;
          color: #000000;
          /* width: 230px; */
        }
        margin-top: 5px;
        .product-subline {
          padding-#{$ldirection}: 45px;
        }
      }
      .sticky_prod_select {
        padding-#{$rdirection}: 150px;
        float: #{$rdirection};
        .btn-add-to-bag {
          float: #{$ldirection};
          margin: 5px 10px;
          &.hidden {
            display: none;
          }
        }
        .btn-not-shoppable {
          float: left;
          margin: 5px 10px;
          height: 37px;
          line-height: 41px;
          font-size: 17px;
          padding: 0 15px;
          background: #cccccc;
          text-transform: uppercase;
          color: white;
          text-align: center;
          display: inline-block;
          letter-spacing: 1px;
          border: 0;
          &.disabled {
            cursor: not-allowed;
          }
          &.hidden {
            display: none;
          }
        }
        .price {
          float: #{$rdirection};
          font-size: 18px;
          font-family: $helvetica-light-font-family;
          padding: 14px 0 0;
        }
        .sticky_prod_select_items {
          float: #{$rdirection};
        }
      }
    }
  }
  .tertiary-nav {
    float: #{$rdirection};
    @include swap_direction(margin, 0 5px 0 0);
    height: 47px;
    z-index: 2;
    background: #fff;
  }
  .icon-search {
    font-size: 20px;
    color: #b0afaf;
  }
  .tertiary-nav li.searchbar {
    position: relative;
  }
  .tertiary-nav > li.searchbar .search-mobile {
    padding: 0;
    color: #b0afaf;
    font-size: 21px;
    display: inline-block;
    @include swap_direction(margin, 4px 0 0 0);
    position: relative;
    text-decoration: none;
    vertical-align: top;
  }
  .search-mobile-form {
    background: #fff;
    display: inline-block;
    z-index: 9000;
    overflow: hidden;
    width: 0;
    .form-type-textfield {
      -webkit-transition: all 0.7s ease 0s;
      -moz-transition: all 0.7s ease 0s;
      -ms-transition: all 0.7s ease 0s;
      -o-transition: all 0.7s ease 0s;
      transition: all 0.7s ease 0s;
    }
    input {
      border: 0;
      @include swap_direction(margin, 2px 0 0 0);
      padding: 0;
      outline: none;
      &.form-submit {
        @include background(none !important);
        background-color: transparent;
        width: 30px;
        height: 30px;
        text-indent: -9999px;
        overflow: hidden;
        position: absolute;
        #{$rdirection}: 0;
        z-index: 999;
        display: none;
      }
      &.form-text {
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.4) inset;
        color: gray;
        font-size: 14px;
        font-family: $helvetica-light-font-family;
        height: 30px;
        line-height: 30px;
        padding: 0 8px;
        width: 260px;
      }
    }
    .form-tablet {
      display: none;
    }
  }
  .tertiary-nav > li {
    display: inline-block;
    border-#{$ldirection}: 1px solid #ddd;
    vertical-align: middle;
    height: 47px;
    text-align: center;
    padding: 7px 8px 0;
    &.live-chat {
      padding-top: 16px;
    }
  }
  .ico-live-chat {
    width: 24px;
    height: 25px;
    display: block;
    background: url('/_onelink_/cliniqueca/projects/cliniqueca/enca2frca/images/media/export/cms/icon-live-chat.png') no-repeat 0 0;
    background-color: transparent;
    border: 0;
    color: transparent;
    font: 0 / 0 a;
    text-shadow: none;
  }
}

.filter_div {
  float: #{$ldirection};
  padding: 18px 30px;
}
/* Exception of tertiary nav on SPP pages (needed after Paul G "more" functionality changes of 6.18) */
.pc_spp_view #sticky-bar .tertiary-nav {
  position: absolute;
  #{$rdirection}: 0;
}

@media screen and (max-width: 940px) {
  #sticky-bar {
    .tertiary-nav {
      display: none;
    }
    #sticky_bar_content {
      .sticky_content .sticky_prod_select {
        padding-#{$rdirection}: 0;
      }
    }
  }
}

@media screen and (max-width: 790px) {
  #sticky-bar #sticky_bar_content {
    .sticky_content .sticky_prod_select .price {
      display: none;
    }
  }
  #sticky-bar .outer-wrap .mm-menu {
    @include swap_direction(padding, 0 10px 0 30px);
    li {
      padding: 0 3px;
      .more-content {
        margin-top: 3px;
      }
    }
  }
}
/* un-comment to see where the sticky swicthing element is placed */
/* .sticky-marker { height: 10px; width: 10px; background-color: #ff0000; z-index: 10000; } */
