@import '../../../cl_base/scss/theme-bootstrap';

.elc-order-tracking {
  &__content {
    .elc-grid-column {
      a.elc-order-tracking-see-delivery-results {
        @include elc-button--light;
        margin-top: 20px;
        padding: 0 15px;
      }
    }
  }
}