.customer-care-our-online-consultants {
  .customer-care-list {
    .customer-care-list-item.first {
      display: none;
    }
    .customer-care-list-item.last {
      margin: 0;
      width: auto;
      .body-content {
        max-height: 200px;
        min-height: 185px;
        p {
          line-height: 1.25em;
          font-size: 1rem;
        }
      }
      #customer_service_chat {
        padding-top: 10px;
        width: 400px;
        text-align: center;
      }
    }
  }
}
